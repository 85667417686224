import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/myLogin",
  },
  {
    path: "/myLogin",
    name: "myLogin",
    component: () => import("../views/loginView.vue"),
    hidden: true,
  },
  {
    path: "/myHome",
    name: "myHome",
    component: () => import("../views/HomeView.vue"),
    hidden: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
