import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: "", //保存用户id
    orderId: ''
  },
  getters: {},
  mutations: {
    setUserId(state, id) {
      state.userId = id;
      // console.log("userid", id);
    },
    setOrderId(state, orderId) {
      state.orderId = orderId;
      // console.log("orderId", orderId);
    },
  },
  actions: {},
  modules: {},
});
