import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/style/reset.css";
import vueEsign from "vue-esign";
Vue.use(vueEsign);

Vue.use(ElementUI);
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  if (to.path === "/myLogin") {
    next();
    // console.log(next)
  } else {
    // 每次页面跳转执行，token或openId錯誤，均提示跳转到首页
    let token = sessionStorage.getItem("setToken");
    if (token === null || token === "" || token === undefined) {
      alert("您还未登录，请先登录");
      setTimeout(function () {
        next("/myLogin");
      }, 1000);
    } else {
      next();
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
